import React, { useState, useEffect, Suspense, lazy } from 'react'
import { BrowserRouter as Router, Routes, Route, } from "react-router-dom";
import './App.css';
import SideMenu from "./Components/SideMenu";
import Home from "./Components/Home";
import Footer from "./Components/Footer";
import loader from './Components/img/loading.png';
import ExpandLess from '@mui/icons-material/ExpandLess';
import load from "./Components/img/loader.gif"

const Contact = lazy(() => import('./Components/Contact'))
const Hiring = lazy(() => import('./Components/Hiring'))
const ServicePage = lazy(() => import('./Components/ServicePage'))
const WebDevelopment = lazy(() => import('./Components/WebDevelopment'))
const MobileApp = lazy(() => import('./Components/MobileApp'))
const WebGraphicDes = lazy(() => import('./Components/WebGraphicDes'))
const Career = lazy(() => import('./Components/Career'))
const GraphicSolotion = lazy(() => import('./Components/GraphicSolotion'))
const WebSolution = lazy(() => import('./Components/WebSolution'))
const AboutUs = lazy(() => import('./Components/AboutUs'))
const CertificateMembership = lazy(() => import('./Components/CertificateMembership'))
const PrivacyPolicy = lazy(() => import('./Components/PrivacyPolicy'))
const CookiesPolicy = lazy(() => import('./Components/CookiesPolicy'))
const Portfolio = lazy(() => import('./Components/Portfolio'))
const Blogs = lazy(() => import('./Components/Blogs'))

function App() {

  const [loading, setLoading] = useState(true);
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const onScroll = e => {
      setShowScroll(e.target.documentElement.scrollTop > 300);
    };

    document.addEventListener('scroll', onScroll);

    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, []);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      {loading ? (
        <div className='bg-gray-600 h-screen'>
          <div className="flex h-screen w-screen items-center justify-center">
            <img src={loader} alt="loader" className="animate-spin" />
          </div>
        </div>
      ) : (<Router>
        <SideMenu />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/contact" element={
            <Suspense fallback={
              <img src={load} alt="loader" className="mx-auto my-8" />}>
              <Contact />
            </Suspense>
          } />
          <Route exact path="/service" element={
            <Suspense fallback={
              <img src={load} alt="loader" className="mx-auto my-8" />}>
              <ServicePage />
            </Suspense>} />
          <Route exact path="/web-development" element={
            <Suspense fallback={
              <img src={load} alt="loader" className="mx-auto my-8" />}>
              <WebDevelopment />
            </Suspense>} />
          <Route exact path="/mobile-app" element={
            <Suspense fallback={
              <img src={load} alt="loader" className="mx-auto my-8" />}>
              <MobileApp />
            </Suspense>} />
          <Route exact path="/web-graphic" element={
            <Suspense fallback={
              <img src={load} alt="loader" className="mx-auto my-8" />}>
              <WebGraphicDes />
            </Suspense>} />
          <Route exact path="/portfolio" element={
            <Suspense fallback={
              <img src={load} alt="loader" className="mx-auto my-8" />}>
              <Portfolio />
            </Suspense>} />
          <Route exact path="/career" element={
            <Suspense fallback={
              <img src={load} alt="loader" className="mx-auto my-8" />}>
              <Career />
            </Suspense>} />
          <Route exact path="/hiring" element={
            <Suspense fallback={
              <img src={load} alt="loader" className="mx-auto my-8" />}>
              <Hiring />
            </Suspense>} />
          <Route exact path="/graphic-solution" element={
            <Suspense fallback={
              <img src={load} alt="loader" className="mx-auto my-8" />}>
              <GraphicSolotion />
            </Suspense>} />
          <Route exact path="/web-solution" element={
            <Suspense fallback={
              <img src={load} alt="loader" className="mx-auto my-8" />}>
              <WebSolution />
            </Suspense>} />
          <Route exact path="/certificates-membership" element={
            <Suspense fallback={
              <img src={load} alt="loader" className="mx-auto my-8" />}>
              <CertificateMembership />
            </Suspense>} />
          <Route exact path="/about-us" element={
            <Suspense fallback={
              <img src={load} alt="loader" className="mx-auto my-8" />}>
              <AboutUs />
            </Suspense>} />
          <Route exact path="/privacy-policy" element={
            <Suspense fallback={
              <img src={load} alt="loader" className="mx-auto my-8" />}>
              <PrivacyPolicy />
            </Suspense>} />
          <Route exact path="/cookies-policy" element={
            <Suspense fallback={
              <img src={load} alt="loader" className="mx-auto my-8" />}>
              <CookiesPolicy />
            </Suspense>} />
          <Route exact path="/blogs" element={
            <Suspense fallback={
              <img src={load} alt="loader" className="mx-auto my-8" />}>
              <Blogs />
            </Suspense>} />
        </Routes>
        <Footer />
      </Router>)}
      <div>
        {showScroll && (
          <button
            id="scroll-top-btn"
            className="fixed bottom-0 right-0 m-6"
            onClick={scrollTop}
          >
            <div className='p-1 rounded-full bg-custom-arrow hover:bg-custom-blue'>
              <ExpandLess sx={{ color: "white", fontSize: "40px" }} />
            </div>
          </button>
        )}
      </div>
    </>
  );
}

export default App;