import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import img from "./img/MenuIcons/VBT-Logo.png";
import { FiPhoneCall } from "react-icons/fi";
import { AiOutlineTwitter } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";
import { BsWhatsapp } from "react-icons/bs";

const Footer = () => {
  const icon = {
    fontSize: "18px",
  };

  const hrStyle = {
    marginBottom: "20px",
  };

  const style = {
    fontFamily: "Poppins, sans-serif",
    fontWeight: "500",
  };

  const style2 = {
    fontFamily: "Poppins, sans-serif",
    fontWeight: "300",
  };

  return (
    <footer className="footerContainer">
      <Box bgcolor="#000000" color="white" padding={4}>
        <Container maxWidth="xl">
          <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={12} marginY={2}>
              {/* animate-bounce: animation for footer logo */}
              <img
                src={img}
                alt="logo"
                width={120}
                height={120}
                className="mx-auto transition duration-500"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} lineHeight={2}>
              <Box
                borderBottom={1}
                marginBottom={4}
                paddingBottom={1}
                sx={{
                  fontSize: "22px",
                  color: "#fff",
                  borderBottom: "2px solid #00AFEF",
                }}
                style={style}
              >
                Company
              </Box>
              <Box sx={{ color: "#bfbfbf" }}>
                <Link
                  className="hover:text-custom-blue"
                  underline="none"
                  to="/certificates-membership"
                  color="inherit"
                  sx={{ ":hover": { color: "#00afef" } }}
                >
                  Certificates/Memberships
                </Link>
              </Box>
              <Box sx={{ color: "#bfbfbf" }}>
                <Link
                  className="hover:text-custom-blue"
                  underline="none"
                  to="/about-us"
                  color="inherit"
                  sx={{ ":hover": { color: "#00afef" } }}
                >
                  About us
                </Link>
              </Box>
              <Box sx={{ color: "#bfbfbf" }}>
                <Link
                  className="hover:text-custom-blue"
                  underline="none"
                  to="/blogs"
                  color="inherit"
                  sx={{ ":hover": { color: "#00afef" } }}
                >
                  Blog
                </Link>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} lineHeight={2}>
              <Box
                borderBottom={1}
                marginBottom={4}
                paddingBottom={1}
                sx={{
                  fontSize: "22px",
                  color: "#fff",
                  borderBottom: "2px solid #00AFEF",
                }}
                style={style}
              >
                Services
              </Box>
              <Box sx={{ color: "#bfbfbf" }}>
                <Link
                  className="hover:text-custom-blue"
                  underline="none"
                  to="/web-development"
                  color="inherit"
                  sx={{ ":hover": { color: "#00afef" } }}
                >
                  Web Development
                </Link>
              </Box>
              <Box sx={{ color: "#bfbfbf" }}>
                <Link
                  className="hover:text-custom-blue"
                  underline="none"
                  to="/mobile-app"
                  color="inherit"
                  sx={{ ":hover": { color: "#00afef" } }}
                >
                  Mobile App Development
                </Link>
              </Box>
              <Box sx={{ color: "#bfbfbf" }}>
                <Link
                  className="hover:text-custom-blue"
                  underline="none"
                  to="/web-graphic"
                  color="inherit"
                  sx={{ ":hover": { color: "#00afef" } }}
                >
                  Graphics Designing
                </Link>
              </Box>
              <Box sx={{ color: "#bfbfbf" }}>
                <Link
                  className="hover:text-custom-blue"
                  underline="none"
                  to="/web-solution"
                  color="inherit"
                  sx={{ ":hover": { color: "#00afef" } }}
                >
                  Web Solution
                </Link>
              </Box>
              <Box sx={{ color: "#bfbfbf" }}>
                <Link
                  className="hover:text-custom-blue"
                  underline="none"
                  to="/graphic-solution"
                  color="inherit"
                  sx={{ ":hover": { color: "#00afef" } }}
                >
                  Graphic Solution
                </Link>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} lineHeight={2}>
              <Box
                borderBottom={1}
                marginBottom={4}
                paddingBottom={1}
                sx={{
                  fontSize: "22px",
                  color: "#fff",
                  borderBottom: "2px solid #00AFEF",
                }}
                style={style}
              >
                How to Find us ?
              </Box>
              <Box>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6723.2407439327535!2d74.067062!3d32.589647!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3e141a53d0c02f1d!2sVisionBird%20Technologies!5e0!3m2!1sen!2sus!4v1674537585251!5m2!1sen!2sus"
                  width="257"
                  height="250"
                  title="VisionBird"
                  style={{ border: "0" }}
                  loading="lazy"
                ></iframe>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} lineHeight={2}>
              <Box
                borderBottom={1}
                marginBottom={4}
                paddingBottom={1}
                sx={{
                  fontSize: "22px",
                  color: "#fff",
                  borderBottom: "2px solid #00AFEF",
                }}
                style={style}
              >
                Contact
              </Box>
              <Box sx={{ color: "#bfbfbf" }}>
                <Box
                  className="hover:text-custom-blue"
                  underline="none"
                  color="inherit"
                >
                  <b className="text-custom-blue">Email: </b>{" "}
                  <a href="mailto:info@visionbird.com">info@visionbird.com</a>
                </Box>
              </Box>
              <Box sx={{ color: "#bfbfbf" }}>
                <Box
                  className="hover:text-custom-blue"
                  underline="none"
                  color="inherit"
                >
                  <b className="text-custom-blue">Contact 1:</b>{" "}
                  <a href="tel:0301 6239096">+92 301 6239096</a>
                </Box>
              </Box>
              <Box sx={{ color: "#bfbfbf" }}>
                <Box
                  className="hover:text-custom-blue"
                  underline="none"
                  color="inherit"
                >
                  <b className="text-custom-blue">Contact 2:</b>{" "}
                  <a href="tel:053-3728469">053-3728469</a>
                </Box>
              </Box>
              <Box sx={{ color: "#bfbfbf" }}>
                <Box
                  className="hover:text-custom-blue"
                  underline="none"
                  color="inherit"
                >
                  <h3>
                    (Call will be answered during these hours: 09:00 a.m. to
                    06:00 p.m. Monday to Friday. Other than these hours we'll
                    not be able to reply you)
                  </h3>
                </Box>
              </Box>
              <Box sx={{ color: "#bfbfbf" }}>
                <Box
                  underline="none"
                  color="inherit"
                  sx={{ ":hover": { color: "#00afef" } }}
                >
                  <b className="text-custom-blue">Address:</b> B-343, Pagganwala
                  Street, Near Cheema Masjid, Shadman Colony, Gujrat, Pakistan.
                </Box>
              </Box>
           
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box bgcolor="#000000" color="white" padding={4}>
        <hr style={hrStyle} />
        <Container>
          <Grid container alignItems={"center"} spacing={5}>
            <Grid item xs={12} sm={6} md={6}>
              <Box sx={{ fontSize: "14px" }} style={style2}>
                All contents © VisionBird Technologies, 2023. <br />
                All Rights Reserved
              </Box>
              <Box sx={{ fontSize: "14px", marginTop: "8px" }}>
                <Link
                  className="hover:text-custom-blue"
                  style={style2}
                  to="/privacy-policy"
                >
                  Privacy Policy
                </Link>{" "}
                |{" "}
                <Link
                  className="hover:text-custom-blue"
                  style={style2}
                  to="/cookies-policy"
                >
                  Cookies Policy{" "}
                </Link>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Stack direction="row" justifyContent={"center"} spacing={3}>
                <a href="tel:053-3728469">
                  <div
                    className="border-2 p-3 rounded-full cursor-pointer hover:bg-custom-phone"
                    style={icon}
                  >
                    <FiPhoneCall />
                  </div>
                </a>

                <a
                  href="https://www.facebook.com/VisionBirdTech"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div
                    className="border-2 p-3 rounded-full cursor-pointer hover:bg-custom-fb"
                    style={icon}
                  >
                    <FaFacebookF />
                  </div>
                </a>

                <a
                  href="https://twitter.com/VisionbirdT"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div
                    className="border-2 p-3 rounded-full cursor-pointer hover:bg-custom-twitter"
                    style={icon}
                  >
                    <AiOutlineTwitter />
                  </div>
                </a>

                <a
                  href="https://api.whatsapp.com/send?phone=923016239096&text=VisionBirdTechnologies"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div
                    className="border-2 p-3 rounded-full cursor-pointer hover:bg-custom-whatsapp"
                    style={icon}
                  >
                    <BsWhatsapp />
                  </div>
                </a>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </footer>
  );
};

export default Footer;
