import React, { useEffect, useState, useRef } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { IoMdMenu } from "react-icons/io";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import logo from "./img/logo-dark.png";
import logo2 from "./img/logo-light.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { Hidden } from "@mui/material";
import { FiPhoneCall } from "react-icons/fi";
import { AiOutlineTwitter } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";
import { BsWhatsapp } from "react-icons/bs";
import icon1 from "./img/MenuIcons/home.png";
import icon2 from "./img/MenuIcons/service.png";
import icon3 from "./img/MenuIcons/portfolio.png";
import icon4 from "./img/MenuIcons/success.png";
import icon5 from "./img/MenuIcons/company.png";
import icon6 from "./img/MenuIcons/contact.png";
import icon7 from "./img/MenuIcons/job.png";
import icon8 from "./img/MenuIcons/solution.png";
import Backdrop from "@mui/material/Backdrop";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import SearchIcon from "@mui/icons-material/Search";
import cross from "./img/MenuIcons/cross.png";

const drawerWidth = 280;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

export default function SideMenu() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  const styleFont = {
    fontFamily: "Poppins, sans-serif",
    backgroundColor: "#FFFFFF",
    padding: "12px 20px",
  };

  const [nestOpen, setnestOpen] = React.useState(false);

  const handleClick = () => {
    document.documentElement.style.overflowY = "unset";
    setnestOpen(!nestOpen);
  };

  const [nestOpen3rd, setnestOpen3rd] = React.useState(false);

  const handleClick3rd = () => {
    document.documentElement.style.overflowY = "unset";
    setnestOpen3rd(!nestOpen3rd);
  };

  const [nestOpen4rd, setnestOpen4rd] = React.useState(false);

  const handleClick4rd = () => {
    document.documentElement.style.overflowY = "unset";
    setnestOpen4rd(!nestOpen4rd);
  };

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    document.documentElement.style.overflowY = "hidden";
    setOpen(true);
  };

  const handleDrawerClose = () => {
    document.documentElement.style.overflowY = "unset";
    setOpen(false);
  };

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    const handleScroll = (event) => {
      if (window.scrollY > 10) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scroll]);

  let menuRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        document.documentElement.style.overflowY = "unset";
        setOpen(false);
        setnestOpen(false);
        setnestOpen3rd(false);
        setnestOpen4rd(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  // ********************** Style When Scroll *****************************

  const absoItem = {
    position: "fixed",
    background: "none",
    boxShadow: "none",
  };
  const fixedItem = {
    position: "fixed",
    background: "#F3F4F4",
    boxShadow: "none",
  };
  const phoneIconDefColor = {
    backgroundColor: "",
    fontSize: "17px",
  };
  const phoneScrollIconDefColor = {
    backgroundColor: "#00afef",
    fontSize: "17px",
  };
  const fbIconDefColor = {
    backgroundColor: "",
    fontSize: "17px",
  };
  const fbScrollIconDefColor = {
    backgroundColor: "#4867AA",
    fontSize: "17px",
  };
  const wsIconDefColor = {
    backgroundColor: "",
    fontSize: "17px",
  };
  const wsScrollIconDefColor = {
    backgroundColor: "#1BD741",
    fontSize: "17px",
  };
  const twiIconDefColor = {
    backgroundColor: "",
    fontSize: "17px",
  };
  const twiScrollIconDefColor = {
    backgroundColor: "#1DA1F2",
    fontSize: "17px",
  };
  const btnStyle = {
    width: "135px",
    height: "42px",
    color: "white",
    fontFamily: "Poppins, sans-serif",
    fontWeight: "500",
    border: "1px solid white",
    ":hover": {
      border: "1px solid white",
    },
  };
  const btnStyleScroll = {
    width: "135px",
    height: "42px",
    color: "#00afef",
    fontFamily: "Poppins, sans-serif",
    fontWeight: "500",
    border: "1px solid #00afef",
    ":hover": {
      border: "1px solid #00afef",
    },
  };
  const menuIconColor = {
    color: "white",
    fontSize: "40px",
    border: "1px solid white",
    borderRadius: "4px",
    padding: "4px",
  };
  const menuIconColorScroll = {
    color: "#00afef",
    fontSize: "40px",
    border: "1px solid #00afef",
    borderRadius: "4px",
    padding: "4px",
  };

  const searchbtnStyle = {
    "& .MuiInputLabel-root": {
      color: "white !important",
      fontFamily: "Poppins, sans-serif !important",
      fontWeight: "500 !important",
    },
    "& path": {
      color: "white !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid white !important",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid white !important",
    },
    ".MuiOutlinedInput-input": {
      color: "white !important",
      fontFamily: "Poppins, sans-serif !important",
      fontWeight: "500 !important",
    },
    ".css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
      color: "white !important",
      fontFamily: "Poppins, sans-serif !important",
      fontWeight: "500 !important",
    },
  };

  const searchStyleScroll = {
    "& .MuiInputLabel-root": {
      color: "#00afef !important",
      fontFamily: "Poppins, sans-serif !important",
      fontWeight: "500 !important",
    },
    "& path": {
      color: "#00afef !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #00afef !important",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #00afef !important",
    },
    ".MuiOutlinedInput-input": {
      color: "black !important",
      fontFamily: "Poppins, sans-serif !important",
      fontWeight: "500 !important",
    },
    ".css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
      color: "#00afef !important",
      fontFamily: "Poppins, sans-serif !important",
      fontWeight: "500 !important",
    },
  };

  // ****************** ToolTip **************************

  const [showPhoneTooltip, setShowPhoneTooltip] = useState(false);
  const [showfbTooltip, setShowfbTooltip] = useState(false);
  const [showtwiTooltip, setShowtwiTooltip] = useState(false);
  const [showwsTooltip, setShowwsTooltip] = useState(false);

  const toolTipStyle = {
    fontFamily: "Poppins, sans-serif",
    bottom: "-28px",
    marginLeft: "-48px",
  };

  // ********************* Search Box ************************
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionSelection = (event, value) => {
    setSelectedOption(value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && selectedOption) {
      window.location.href = selectedOption.url;
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar open={open} sx={scroll ? fixedItem : absoItem}>
        <Toolbar>
          <Box style={{ flexGrow: 1, padding: "14px" }}>
            <Link to="/">
              <img src={scroll ? logo : logo2} alt="logo" width={"200px"} />
            </Link>
          </Box>
          <Stack direction="row" spacing={1}>
            <Hidden mdDown>
              <a
                href="tel:053-3728469"
                onMouseEnter={() => setShowPhoneTooltip(true)}
                onMouseLeave={() => setShowPhoneTooltip(false)}
              >
                <div
                  className="border-2 p-3 rounded-full cursor-pointer hover:bg-custom-phone"
                  style={scroll ? phoneScrollIconDefColor : phoneIconDefColor}
                >
                  <FiPhoneCall />
                </div>
                {showPhoneTooltip && (
                  <div
                    className="bg-custom-blue rounded-lg text-white text-sm p-2 absolute"
                    style={toolTipStyle}
                  >
                    tel: 053-3728469
                  </div>
                )}
              </a>

              <a
                href="https://www.facebook.com/VisionBirdTech"
                target="_blank"
                rel="noreferrer"
                onMouseEnter={() => setShowfbTooltip(true)}
                onMouseLeave={() => setShowfbTooltip(false)}
              >
                <div
                  className="border-2 p-3 rounded-full cursor-pointer hover:bg-custom-fb"
                  style={scroll ? fbScrollIconDefColor : fbIconDefColor}
                >
                  <FaFacebookF />
                </div>
                {showfbTooltip && (
                  <div
                    className="bg-custom-fb rounded-lg text-white text-sm p-2 absolute"
                    style={toolTipStyle}
                  >
                    Facebook/VisionBirdTech
                  </div>
                )}
              </a>

              <a
                href="https://twitter.com/VisionbirdT"
                target="_blank"
                rel="noreferrer"
                onMouseEnter={() => setShowtwiTooltip(true)}
                onMouseLeave={() => setShowtwiTooltip(false)}
              >
                <div
                  className="border-2 p-3 rounded-full cursor-pointer hover:bg-custom-twitter"
                  style={scroll ? twiScrollIconDefColor : twiIconDefColor}
                >
                  <AiOutlineTwitter />
                </div>
                {showtwiTooltip && (
                  <div
                    className="bg-custom-twitter rounded-lg text-white text-sm p-2 absolute"
                    style={toolTipStyle}
                  >
                    Twitter/VisionBirdTech
                  </div>
                )}
              </a>

              <a
                href="https://api.whatsapp.com/send?phone=923016239096&text=VisionBirdTechnologies"
                target="_blank"
                rel="noreferrer"
                onMouseEnter={() => setShowwsTooltip(true)}
                onMouseLeave={() => setShowwsTooltip(false)}
              >
                <div
                  className="border-2 p-3 rounded-full cursor-pointer hover:bg-custom-whatsapp"
                  style={scroll ? wsScrollIconDefColor : wsIconDefColor}
                >
                  <BsWhatsapp />
                </div>
                {showwsTooltip && (
                  <div
                    className="bg-custom-whatsapp rounded-lg text-white text-sm p-2 absolute"
                    style={toolTipStyle}
                  >
                    WhatsApp/03016239096
                  </div>
                )}
              </a>

              <Link to="/contact">
                <Button
                  sx={scroll ? btnStyleScroll : btnStyle}
                  variant="outlined"
                  color="error"
                >
                  Contact Us
                </Button>
              </Link>
            </Hidden>
          </Stack>
          <Hidden mdDown>
            <Autocomplete
              freeSolo
              disablePortal
              id="combo-box-demo"
              options={search}
              onChange={handleOptionSelection}
              onKeyDown={handleKeyDown}
              sx={{ width: 200, marginLeft: "10px", marginBottom: "4px" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    <div>
                      <SearchIcon style={{ marginRight: "0.5em" }} />
                      Search
                    </div>
                  }
                  placeholder="Enter Keyword"
                  size="small"
                  sx={scroll ? searchStyleScroll : searchbtnStyle}
                />
              )}
            />
          </Hidden>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            sx={{
              ...(open && { display: "none" }),
              marginLeft: "3px",
              top: "-2px",
            }}
          >
            <div className="">
              <IoMdMenu style={scroll ? menuIconColorScroll : menuIconColor} />
            </div>
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer
        BackdropProps={{ invisible: false }}
        ref={menuRef}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            backgroundColor: "#F9F9F9",
            padding: "0px 5px",
            width: drawerWidth,
            zIndex: 1400,
          },
          "& .MuiDivider-root": {
            border: "none",
          },
          "& .MuiListItemButton-root:hover": {
            backgroundColor: "#EBEBEB",
            color: "black",
          },
          "& .MuiList-root": {
            background: "none",
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            <img
              src={cross}
              alt="cross"
              className="h-7 w-7 hover:rotate-90 transition duration-500"
            />
          </IconButton>
        </DrawerHeader>
        <Divider />

        <List
          sx={{
            width: "100%",
            maxWidth: 360,
            bgcolor: "background.paper",
          }}
          component="nav"
        >
          {/* ******* HOME ****** */}
          <Link to="/" onClick={handleDrawerClose}>
            <ListItemButton style={{ margin: "5px 0px" }}>
              <ListItemIcon>
                <img src={icon1} alt="icon1" width="32px" />
              </ListItemIcon>
              <ListItemText
                sx={{
                  ".MuiListItemText-primary": {
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "500",
                    fontSize: "18px",
                  },
                }}
                primary="HOME"
              />
            </ListItemButton>
          </Link>

          {/* ******* SERVICES ****** */}
          <ListItemButton onClick={handleClick} style={{ marginBottom: "5px" }}>
            <ListItemIcon>
              <img src={icon2} alt="icon2" width="32px" />
            </ListItemIcon>
            <ListItemText
              sx={{
                ".MuiListItemText-primary": {
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "500",
                  fontSize: "18px",
                },
              }}
              primary="SERVICES"
            />
            {nestOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>

          <Collapse in={nestOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to="/web-development"
                onClick={(e) => {
                  setOpen(false);
                  setnestOpen(!nestOpen);
                }}
              >
                <div
                  className="p-1 my-1 text-black hover:text-custom-blue"
                  style={styleFont}
                >
                  Web Development
                </div>
              </Link>

              <Link
                to="/mobile-app"
                onClick={(e) => {
                  setOpen(false);
                  setnestOpen(!nestOpen);
                }}
              >
                <div
                  className="p-1 my-1 text-black hover:text-custom-blue"
                  style={styleFont}
                >
                  Mobile App Development
                </div>
              </Link>

              <Link
                to="/web-graphic"
                onClick={(e) => {
                  setOpen(false);
                  setnestOpen(!nestOpen);
                }}
              >
                <div
                  className="p-1 my-1 text-black hover:text-custom-blue"
                  style={styleFont}
                >
                  Graphic Designing
                </div>
              </Link>
            </List>
          </Collapse>

          {/* ******* portfolio ****** */}
          <Link to="/portfolio" onClick={handleDrawerClose}>
            <ListItemButton style={{ marginBottom: "5px" }}>
              <ListItemIcon>
                <img src={icon3} alt="icon3" width="32px" />
              </ListItemIcon>
              <ListItemText
                sx={{
                  ".MuiListItemText-primary": {
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "500",
                    fontSize: "18px",
                  },
                }}
                primary="PORTFOLIO"
              />
            </ListItemButton>
          </Link>

          {/* ******* CAREER ****** */}
          <Link to="/career" onClick={handleDrawerClose}>
            <ListItemButton style={{ marginBottom: "5px" }}>
              <ListItemIcon>
                <img src={icon4} alt="icon3" width="32px" />
              </ListItemIcon>
              <ListItemText
                sx={{
                  ".MuiListItemText-primary": {
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "500",
                    fontSize: "18px",
                  },
                }}
                primary="CAREER"
              />
            </ListItemButton>
          </Link>

          {/* ******* Solution ****** */}
          <ListItemButton
            onClick={handleClick3rd}
            style={{ marginBottom: "5px" }}
          >
            <ListItemIcon>
              <img src={icon8} alt="icon4" width="28px" />
            </ListItemIcon>
            <ListItemText
              sx={{
                ".MuiListItemText-primary": {
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "500",
                  fontSize: "18px",
                },
              }}
              primary="SOLUTIONS"
            />
            {nestOpen3rd ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>

          <Collapse in={nestOpen3rd} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to="/graphic-solution"
                onClick={(e) => {
                  setOpen(false);
                  setnestOpen3rd(!nestOpen3rd);
                }}
              >
                <div
                  className="p-1 mb-1 text-black hover:text-custom-blue"
                  style={styleFont}
                >
                  Graphic Solution
                </div>
              </Link>

              <Link
                to="/web-solution"
                onClick={(e) => {
                  setOpen(false);
                  setnestOpen3rd(!nestOpen3rd);
                }}
              >
                <div
                  className="p-1 text-black hover:text-custom-blue"
                  style={styleFont}
                >
                  Web Solution
                </div>
              </Link>
            </List>
          </Collapse>

          {/* ******* Apply for Job ****** */}
          <Link to="/hiring" onClick={handleDrawerClose}>
            <ListItemButton style={{ marginBottom: "5px" }}>
              <ListItemIcon>
                <img src={icon7} alt="icon7" width="32px" />
              </ListItemIcon>
              <ListItemText
                sx={{
                  ".MuiListItemText-primary": {
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "500",
                    fontSize: "18px",
                  },
                }}
                primary="APPLY FOR JOB"
              />
            </ListItemButton>
          </Link>

          {/* ******* company ****** */}
          <ListItemButton
            onClick={handleClick4rd}
            style={{ marginBottom: "5px" }}
          >
            <ListItemIcon>
              <img src={icon5} alt="icon5" width="32px" />
            </ListItemIcon>
            <ListItemText
              sx={{
                ".MuiListItemText-primary": {
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "500",
                  fontSize: "18px",
                },
              }}
              primary="COMPANY"
            />
            {nestOpen4rd ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>

          <Collapse in={nestOpen4rd} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to="/certificates-membership"
                onClick={(e) => {
                  setOpen(false);
                  setnestOpen4rd(!nestOpen4rd);
                }}
              >
                <div
                  className="p-1 mb-1 text-black hover:text-custom-blue"
                  style={styleFont}
                >
                  Certificates/Membership
                </div>
              </Link>

              <Link
                to="/about-us"
                onClick={(e) => {
                  setOpen(false);
                  setnestOpen4rd(!nestOpen4rd);
                }}
              >
                <div
                  className="p-1 mb-1 text-black hover:text-custom-blue"
                  style={styleFont}
                >
                  About Us
                </div>
              </Link>
            </List>
          </Collapse>

          {/* ******* contact ****** */}
          <Link to="/contact" onClick={handleDrawerClose}>
            <ListItemButton style={{ marginBottom: "5px" }}>
              <ListItemIcon>
                <img src={icon6} alt="icon6" width="32px" />
              </ListItemIcon>
              <ListItemText
                sx={{
                  ".MuiListItemText-primary": {
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "500",
                    fontSize: "18px",
                  },
                }}
                primary="CONTACT US"
              />
            </ListItemButton>
          </Link>
        </List>
        <Divider />
      </Drawer>
      <Backdrop
        open={open}
        style={{
          zIndex: 1300,
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          opacity: 0.9,
        }}
      />
    </Box>
  );
}

const search = [
  { label: "Service", url: "/service" },
  { label: "Web Development", url: "/web-development" },
  { label: "Mobile Development", url: "/mobile-app" },
  { label: "Graphic Design", url: "/web-graphic" },
  { label: "Web Solution", url: "/web-solution" },
  { label: "Graphic Solution", url: "/graphic-solution" },
  { label: "Career", url: "/career" },
  { label: "Contact Us", url: "/contact" },
  { label: "Portfolio", url: "/portfolio" },
  { label: "Company", url: "/about-us" },
  { label: "About Us", url: "/about-us" },
  { label: "Certificates", url: "/certificates-membership" },
  { label: "Membership", url: "/certificates-membership" },
  { label: "Apply for job", url: "/hiring" },
  { label: "Expertise", url: "/service" },
];
