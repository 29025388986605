import React, { useEffect, Suspense, lazy } from 'react'
import Hero from './Hero'
import loader from "./img/loader.gif"

const ContentData = lazy(() => import('./ContentData'))
const Certificate = lazy(() => import('./Certificate'))
const Expertise = lazy(() => import('./Expertise'))
const Tech = lazy(() => import('./Tech'))
// const Testimonials = lazy(() => import('./Testimonials'))
const TechCards = lazy(() => import('./TechCards'))
const Team = lazy(() => import('./Team'))

const Home = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <Hero />
            <Suspense fallback={
                <img src={loader} alt="loader" className="mx-auto my-8" />}>
                <ContentData />
            </Suspense>
            <Suspense fallback={
                <img src={loader} alt="loader" className="mx-auto my-8" />}>
                <TechCards />
            </Suspense>
            <Suspense fallback={
                <img src={loader} alt="loader" className="mx-auto my-8" />}>
                <Expertise />
            </Suspense>
            <Suspense fallback={
                <img src={loader} alt="loader" className="mx-auto my-8" />}>
                <Tech />
            </Suspense>
            <Suspense fallback={
                <img src={loader} alt="loader" className="mx-auto my-8" />}>
                <Team />
            </Suspense>
            {/* <Suspense fallback={
                <img src={loader} alt="loader" className="mx-auto my-8" />}>
                <Testimonials />
            </Suspense> */}
            <Suspense fallback={
                <img src={loader} alt="loader" className="mx-auto my-8" />}>
                <Certificate />
            </Suspense>
        </>
    )
}

export default Home