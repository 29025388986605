import React, { useState, useRef } from "react";
import bg from './img/bgVideo.mp4'
import img from './img/bgHead.png'

export default function Hero() {

  const style = {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: '800',
  }
  const style2 = {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: '500',
  }

  const videoRef = useRef(null);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handleVideoLoad = () => {
    setHasLoaded(true);
  };

  const handleVideoError = (event) => {
    setHasError(true);
    console.error("Video error:", event.target.error);
  };

  return (
    <>
      <div className="relative h-screen mx-auto">
        <div
          className={`${(hasLoaded || hasError) ? "hidden" : "block"
            } bg-cover bg-center h-screen`}
          style={{ backgroundImage: `url(${img})` }}>
          <div className="backdrop-brightness-50">
            <div className="container mx-auto flex flex-wrap flex-col items-center justify-center h-screen">
              <div className="text-center mx-auto px-6">
                <h1 className="text-5xl sm:text-4xl lg:text-7xl text-slate-50 mb-5" style={style}>INSTINCTIVE & DISTINCTIVE</h1>
                <p className="text-3xl text-slate-50" style={style2}>Next Level Design</p>
              </div>
            </div>
          </div>
        </div>
        <div ref={videoRef} className={`${hasLoaded ? "block" : "hidden"} `}
        onLoadedData={handleVideoLoad}
        onError={handleVideoError}>
          <video
            className="absolute top-0 left-0 w-full h-screen object-cover"
            autoPlay loop muted>
            <source src={bg} type="video/mp4" />
          </video>
          <div>
            <div className="backdrop-brightness-50">
              <div className="container mx-auto flex flex-wrap flex-col items-center justify-center h-screen">
                <div className="text-center mx-auto px-6">
                  <h1 className="text-5xl sm:text-4xl lg:text-7xl text-slate-50 mb-5" style={style}>INSTINCTIVE & DISTINCTIVE</h1>
                  <p className="text-3xl text-slate-50" style={style2}>Next Level Design</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}